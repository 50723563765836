<template>
  <div class="organization-already-real-name">
    <div class="content">
      <div :class="['bg',{'bg-gray':examine}]"></div>
      <div class="info">
        <div class="name">
          <span :class="['line-left',{'line-left-gray':examine}]"></span>
          <span class="txt">{{ realName }}</span>
          <span :class="['line-right',{'line-right-gray':examine}]"></span>
        </div>
        <div class="mess">{{examine ? "已提交实名认证，审核中，感谢您的支持！" : "您已经完成实名认证，感谢您的支持！"}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrganizationAlreadyRealName",
  props: ["examine","realName"],
};
</script>

<style lang="scss" scoped>
.organization-already-real-name {
  .content {
    margin: 94px auto 0;
    width: 481px;
    .bg {
      height: 267px;
      background: url("~assets/images/organize-already-bg.png") no-repeat;
      background-size: 100% 100%;
      &.bg-gray{
        background: url("~assets/images/organize-examine-bg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .info {
      .name {
        @include flex-center;
        margin: 10px auto 0;
        .txt {
          max-width: 260px;
          padding: 2px 15px;
          line-height: 1.2em;
        }
        position: relative;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        .line-left {
          position: relative;
          width: 89px;
          height: 12px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 6px);
            width: 89px;
            height: 12px;
            background: url("~assets/images/real-line-left.png") no-repeat;
            background-size: cover;
          }
          &.line-left-gray::before{
            background: url("~assets/images/real-line-left-gray.png") no-repeat;
            background-size: cover;
          }
        }
        .line-right {
          position: relative;
          width: 89px;
          height: 11px;
          &::before {
            content: "";
            position: absolute;
            right: 0;
            top: calc(50% - 6px);
            width: 89px;
            height: 11px;
            background: url("~assets/images/real-line-right.png") no-repeat;
            background-size: cover;
          }
          &.line-right-gray::before{
            background: url("~assets/images/real-line-right-gray.png") no-repeat;
            background-size: cover;
          }
        }
      }
      .mess {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
